//
//
//
//

import { get } from 'lodash'

export default {
    head: function () {
        return {
            ...this.pageHead(this.page),
            htmlAttrs: {
                'data-navbar-transparent': true
            }
        }
    },
    methods: { get },
    async asyncData ({ error, $contentful }) {
        const page = await $contentful.getPage('System', 'index')
        if (!page) {
            error({ statusCode: 404, message: true })
        }

        return { page }
    }
}
